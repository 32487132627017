<template>
	<div class="Feedback w">
		<!-- 面包屑 start -->
		<div class="bread flex align-center font-33 ptb24">
			您现在的位置：<el-breadcrumb
				separator="/"
				separator-class="el-icon-arrow-right"
			>
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>意见反馈</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<!-- 面包屑 end -->
		<div class="con-wrap bgfff br4">
			<div
				class="tit fs18 fw-7 font-33 pr00 pb24 flex justify-between align-center"
			>
				<div class="tit-line flex">
					<span class="pr">意见反馈</span>
					<span class="line">意见反馈</span>
				</div>

				<el-popover width="400" trigger="click" popper-class="feedback-popover">
					<span slot="reference" class="right fs14">反馈记录</span>
					<div v-loading="infiniteLoading" class="my-feedback">
						<div v-if="arrs.length > 0 || infiniteLoading">
							<ul class="infinite-list p24">
								<li
									v-for="(item, index) in arrs"
									:key="index"
									class="flex item-list"
								>
									<div class="avatar overHidden">
										<img :src="$store.state.userInfo.head_img" alt="头像" />
									</div>
									<div class="item-desc">
										<div class="fs14 font-33">
											{{ item.body }}
										</div>
										<div class="img-list mt12" v-if="item.thumb.length">
											<div
												v-for="(imgItem, imgIndex) in item.thumb"
												:key="imgIndex"
											>
												<img :src="imgItem" />
											</div>
										</div>
										<div class="fs12 mt12 font-33">
											联系方式:
											<span class="">{{ item.contact || "无" }}</span>
										</div>
										<div class="fs12 mt12 font-99">
											反馈类型:
											<span class="">{{ item.type || "无" }}</span>
										</div>
										<div class="fs12 mt12 font-99">
											反馈时间:
											<span class="">{{ item.create_time }}</span>
										</div>
										<div
											class="flex replay-content fs12 mt12"
											v-if="item.replay"
										>
											<div class="replay-desc">平台回复：</div>
											<div class="replay-text">{{ item.replay }}</div>
										</div>
									</div>
								</li>
							</ul>
							<p v-if="noMore" class="text-c pb32">没有更多了</p>
						</div>
						<div
							v-else
							class="mt24 bgfff br4"
							style="overflow: hidden; padding-bottom: 60px"
						>
							<Empty></Empty>
						</div>
					</div>
				</el-popover>
			</div>
			<div class="con">
				<div class="item type">
					<div class="item-name">反馈类型</div>
					<div class="item-div">
						<div
							class="mb12"
							v-for="(item, index) in feedbackType"
							:key="item.id"
							:class="{ active: item.checked }"
							@click="checkedFeedbackType(item, index)"
						>
							{{ item.title }}
						</div>
					</div>
				</div>
				<div class="item">
					<div class="item-name">
						反馈内容
						<div class="fs14 mt16" style="color: #4177dd">
							您的意见是我们前进的动力：
						</div>
					</div>
					<div class="item-div">
						<el-input
							v-model="textarea"
							type="textarea"
							placeholder="请输入您的意见或反馈(最多200字)"
							show-word-limit
							maxlength="200"
							minlength="10"
							:resize="'none'"
						></el-input>
					</div>
				</div>
				<div class="item" style="padding-top: 0">
					<div class="item-div">
						<el-upload
							:accept="'.png,.jpg,.jpeg'"
							class="upload-demo"
							:class="{ none: fileList.length >= fileListNum }"
							:action="'/api' + $API.uploadFile"
							:before-upload="beforeUpload"
							list-type="picture-card"
							:file-list="fileList"
							:on-success="onSuccess"
							:on-remove="onRemove"
							:on-preview="onPreview"
						>
							<img src="@/assets/imgs/about-upload.png" />
						</el-upload>
						<!-- <el-upload
							class="upload-demo"
							:class="{ none: fileList.length >= fileListNum }"
							action="#"
							:before-upload="beforeUpload"
							:http-request="fOssUpload"
							:file-list="fileList"
							:on-preview="onPreview"
							:on-remove="onRemove"
							list-type="picture-card"
							:auto-upload="true"
						>
							<img src="@/assets/imgs/about-upload.png" />
						</el-upload> -->
					</div>
				</div>
				<div class="item">
					<div class="item-name">
						联系方式<span class="font-99">(选填)</span>
					</div>
					<div class="item-div">
						<el-input
							style="width: 360px"
							v-model="contact"
							placeholder="手机号/邮箱/QQ号"
							clearable
						/>
					</div>
				</div>
				<div class="item">
					<div class="item-div flex justify-center">
						<button type="button" @click="addFeedback">提交反馈</button>
					</div>
				</div>
			</div>
		</div>

		<!-- 图片预览 -->
		<el-dialog
			fullscreen
			:visible.sync="dialogVisible"
			custom-class="preview-img-dialog"
		>
			<div class="pr00 w100 h100">
				<img
					style="
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						height: 100%;
						width: auto;
					"
					:src="dialogImageUrl"
				/>
			</div>
		</el-dialog>
	</div>
</template>
<script>
export default {
	name: "Feedback",
	data() {
		return {
			title: "",
			loading: false,

			dialogImageUrl: "", // 图片预览路径
			dialogVisible: false, // 图片预览弹窗
			feedbackType: [], // 反馈类型

			fileList: [], // 图片上传
			fileListNum: 6, // 最多可以有几张图片
			contact: "", // 意见反馈联系方式
			textarea: "", // 意见反馈详细内容

			fileList2: [], // 存储的

			arrs: [], // 反馈历史
			infiniteLoading: false,
			noMore: false,
		};
	},
	created() {
		this.$pageScrollTop();
		this.getFeedbackType();

		this.getMyfeedback();
	},

	methods: {
		// 获取我的反馈
		getMyfeedback() {
			this.infiniteLoading = true;

			this.$API
				.myFeedback()
				.then((res) => {
					this.infiniteLoading = false;
					this.noMore = true;
					if (res.code == 1) {
						this.arrs = res.data;
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
		// 上传完成
		onSuccess(e) {
			this.fileList2 = [...this.fileList2, ...e.data];
		},
		// 上传之前
		beforeUpload(file) {
			if (this.fileList.length >= this.fileListNum) {
				this.$message.warning("照片最多只能上传" + this.fileListNum + "张");
				return false;
			}

			const isPNG = file.type === "image/png";
			const isJPG = file.type === "image/jpg";
			const isJPEG = file.type === "image/jpeg";

			const isLt10M = file.size / 1024 / 1024 < 10;

			if (!isPNG && !isJPG && !isJPEG) {
				this.$message.error("上传图片只能是 PNG或者JPG或者JPEG 格式!");
			}
			if (!isLt10M) {
				this.$message.error("上传图片大小不能超过 10MB!");
			}
			return (isPNG || isJPG || isJPEG) && isLt10M;
		},
		// 删除图片列表
		onRemove(e) {
			this.fileList2 = this.fileList2.filter((item) => {
				return item.id != e.response.data[0].id;
			});
		},
		// 预览大图
		onPreview(e) {
			this.dialogImageUrl = e.response.data[0].path;
			this.dialogVisible = true;
		},
		// 改变 意见反馈的类型
		checkedFeedbackType(item, index) {
			this.feedbackType = this.feedbackType.map((m, i) => {
				return {
					...m,
					checked: index == i,
				};
			});
		},
		// 获取反馈类型
		getFeedbackType() {
			this.$API
				.getFeedbackType()
				.then((res) => {
					if (res.code == 1) {
						this.feedbackType = res.data.map((item, index) => {
							return {
								...item,
								checked: index == 0,
							};
						});
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},

		// 提交意见反馈
		addFeedback() {
			let { loading, feedbackType, textarea, fileList2, contact } = this;

			if (loading) {
				return false;
			}

			let typeStr = feedbackType.filter((item) => {
				return item.checked;
			})[0].id;

			if (!textarea || !textarea.toString().trim()) {
				this.$message.warning("请输入反馈内容");
				return false;
			}

			if (textarea.length > 200) {
				this.$message.warning("请输入正确的反馈内容");
				return false;
			}

			// if (!contact || !contact.toString().trim()) {
			// 	this.$message.warning("请输入联系方式");
			// 	return false;
			// }

			if (
				contact &&
				!this.$validate(contact, "phone") &&
				!this.$validate(contact, "email") &&
				!this.$validate(contact, "isqq")
			) {
				this.$message.warning("请输入正确的联系方式");
				return false;
			}

			this.loading = true;

			this.$API
				.addFeedback({
					type: typeStr,
					body: textarea,
					thumb: fileList2
						.map((item) => {
							return item.id;
						})
						.join(","),
					contact,
				})
				.then((res) => {
					this.loading = false;
					if (res.code == 1) {
						this.$message.success("提交成功");

						this.feedbackType = this.feedbackType.map((item, index) => {
							return {
								...item,
								checked: index == 0,
							};
						});
						this.textarea = "";
						this.fileList = [];
						this.fileList2 = [];
						this.contact = "";
						this.$pageScrollTop();
						this.getMyfeedback();
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					this.loading = false;
					console.log("err", err);
				});
		},
	},
};
</script>
<style lang="scss">
.feedback-popover {
	margin: 0;
	padding: 0 !important;
}
</style>

<style scoped lang="scss">
::v-deep {
	.upload-demo {
		.el-upload-list__item,
		.el-upload {
			width: 88px;
			height: 88px;
			background: transparent;
		}

		&.avatar {
			.el-upload-list__item,
			.el-upload {
				background: transparent;
			}
		}

		.el-upload {
			position: relative;
			left: 0;
			top: 0;
			border: none;

			img {
				position: absolute;
				left: 0;
				top: 0;
				// left: 50%;
				// top: 50%;
				// transform: translate(-50%, -50%);
				// width: 44px;
				// height: 44px;
				width: 100%;
				height: 100%;
			}
		}

		&.none {
			.el-upload--picture-card {
				display: none; /* 上传按钮隐藏 */
			}
		}

		.el-upload-list__item.is-ready {
			display: none !important;
		}
	}

	.el-textarea {
		textarea {
			height: 148px;
		}
	}
}

.Feedback {
	@extend .h100;
	padding-bottom: 64px;

	.con-wrap {
		padding: 32px 48px;

		.tit {
			border-bottom: 1px dashed #eeeeee;

			.right {
				cursor: pointer;
				color: #4177dd;
			}
		}

		.con {
			flex: 1;
			overflow: auto;

			.item {
				padding-top: 32px;

				.item-name {
					font-size: 16px;
					font-weight: 400;
					color: #333333;
				}

				.item-div {
					margin-top: 16px;

					button {
						border-radius: 100px;
						width: 240px;
						height: 48px;
						background-color: #ff8300;
						font-size: 16px;
						font-weight: 400;
						color: #ffffff;
					}
				}

				&.type {
					.item-div {
						display: flex;
						flex-wrap: wrap;

						div {
							padding: 10px 24px;
							background: #f6f7f9;
							border-radius: 100px;
							font-size: 14px;
							font-weight: 400;
							color: #333333;
							margin-right: 24px;
							cursor: pointer;
							border: 1px solid transparent;

							&.active,
							&:hover {
								background-color: transparent;
								color: #ff8300;
								border: 1px solid #ff8300;
							}
						}
					}
				}
			}
		}
	}
}

.my-feedback {
	height: 60vh;
	min-width: 380px;
	overflow: auto;

	.infinite-list {
		.item-list {
			padding-bottom: 30px;
			padding-top: 10px;

			.avatar {
				height: 40px;
				width: 40px;
				border-radius: 50%;

				img {
					height: 40px;
					width: 40px;
					border-radius: 50%;
				}
			}

			.item-desc {
				padding-left: 16px;

				// 反馈图片 start
				.img-list {
					position: relative;
					display: flex;
					flex-wrap: wrap;

					img {
						width: 72px;
						height: 72px;
						border-radius: 8px;
						margin-right: 12px;
						margin-bottom: 12px;
					}
				}

				// 反馈图片 end

				.replay-content {
					padding: 16px;
					border-radius: 10px;
					background: #f6f7f9;
					overflow: hidden;

					.replay-desc {
						color: #4177dd !important;
					}

					.replay-text {
						flex: 1;
						overflow: hidden;
						width: 100%;
					}
				}
			}
		}
	}
}
</style>
